:root {
  font-family: "Open Sans", sans-serif;
}

.prevent-scrolling {
  height: 100vh;
  overflow: hidden;
}

thead {
  background: #f2f2f2 0% 0% no-repeat padding-box !important;
  color: #4d4d4d !important;
  letter-spacing: 0 !important;

  opacity: 1 !important;
  font-size: 12px !important;
}
.forgotspan {
  cursor: pointer;
}
.lastLogin {
  color: #aaa;
  text-align: center;
  font-size: 14px;
  margin-top: 35px;
}
.formmargin {
  margin-top: 100px;
}
.editheading {
  padding-bottom: 25px;
}
.loading {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  /*z-index: 100;*/
  width: 100vw;
  height: 100vh;
  background-color: white;
  background-image: url("../../assets/images/loading.gif");
  background-repeat: no-repeat;
  background-position: center;
}
.error {
  font-size: 14px;
  margin-bottom: 0px;
  color: #f7931e;
}
.logout {
  cursor: pointer;
}
.adminoption {
  margin-bottom: 15px;
  letter-spacing: 0;
  color: #4d4d4d;
  font-size: 12px;
  box-sizing: border-box;
}
.adminoption.selected {
  border-left: 5px solid #39b54a;
  padding-left: 7px;
}
.admin,
.adminname,
.adminoption {
  padding-left: 12px;
}
.paddingsearchleft {
  padding-left: 0px !important;
}
.paddingsearchright {
  padding-right: 0px !important;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.marginside {
  margin-top: 25px;
}
.loginbtn1.create-account-btn {
  width: calc(100% - 90px);
  margin: 0px 45px;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
}
.loginbtn1.edit-account-btn,
.loginbtn2.edit-account-btn {
  width: calc(100% - 30px);
  margin: 0 15px;
}
.adminname {
  font-size: 16px;
  font-weight: bold;
  color: #4d4d4d;
  opacity: 1;
  top: 75px;
  left: 27px;
  /* width: 108px; */
}
.cross {
  font-size: 11px !important;
}
.admin {
  font-size: 14px;
  color: #4d4d4d;
  line-height: 16px;
}
.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none !important;
}
.sidebar {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  opacity: 1;
  min-height: 92vh;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.sidepadding {
  padding: 0 75px 75px !important;
}
.addaccount-container {
  margin-left: auto;
  margin-right: 0;
}
.abovetable {
  margin-bottom: 1rem;
}
.addaccount {
  background: #39b54a 0% 0% no-repeat padding-box !important;
  border-radius: 3px !important;
  opacity: 1 !important;
  padding: 5px !important;
  font-size: 12px !important;
  float: right;
}
.rowmargin {
  margin-top: 25px;
}
.searchbar {
  border: 1px solid #cccccc;
  border-radius: 3px;
  opacity: 1;

  width: 110%;
  /*margin-top: 100px;*/
  margin-bottom: 15px;
}
.editbtn {
  color: #39b54a !important;
  cursor: pointer;
}
.table td,
.table th {
  letter-spacing: 0 !important;
  color: #4d4d4d !important;
  opacity: 1;
  font-size: 12px !important;
  padding: 0.75rem !important;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  min-width: calc(100% / 8) !important;
}

.formfont {
  text-align: left;
  font-size: 13px;
  letter-spacing: 0;
  color: #999999;
  opacity: 1;
  margin-top: 5px;
}

.check {
  width: 15px !important;
  margin-top: -5px !important;
}
.btn-secondary {
  color: #fff;
  background-color: green !important;
  border-color: #6c757d;
}
.cancle {
  float: right;
  background: white !important;
  border: white !important;
  /* margin-top: -55px !important; */
  color: black !important;
  font-weight: 700 !important;
  margin-bottom: 0.5rem !important;
  padding: 0 !important;
}
.modal-heading {
  margin-bottom: 0.5rem;
  float: left;
}
*:focus {
  outline: none;
}
.editscreen {
  width: 500px;
  margin: 100px auto;
}
.hrpadding {
  padding-bottom: 25px;
  clear: both;
}
.hrpadding1 {
  padding-bottom: 20px;
  padding-top: 20px;
}
.hrpadding2 {
  padding-bottom: 25px;
}
.center {
  text-align: center;
  padding-bottom: 20px;
}
.centeruser {
  margin-top: -35px !important;
  text-align: center;
  padding-bottom: 20px;
}

.navbar {
  background: #63b172;
  height: 58px;
}

.centerrecover {
  text-align: left;
  padding-bottom: 20px;
  padding-top: 30px;
}
.styles_closeButton__20ID4 {
  display: none !important;
}
.styles_modal__gNwvD {
  padding: 0px !important;
}
h2 {
  text-align: center;
}
thead {
  background: black;
  color: white;
}
.table-container {
  overflow-x: scroll;
}
.tablebox {
  border: 1px solid #f2f2f2;
  /*box-shadow: 1px 6px 19px 0px grey;*/
}
.custommodal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
.loginbtn2 {
  opacity: 1;
  margin: 20px 30px;
  border-radius: 7px !important;
  width: 85%;
  background: white;
  background-color: white !important;
  color: black !important;
  font-weight: 600 !important;
  border-color: rgb(95, 179, 88) !important;
  float: right;
  margin-left: 10px;
}

.loginbtn2.warning {
  border-color: #ef5a25 !important;
}
.loginbtn2.meh {
  border-color: #f6941f !important;
}
.loginbtn2.success {
  border-color: rgb(95, 179, 88) !important;
}
.loginbtn2.warning.fill {
  background-color: #ef5a25 !important;
}
.loginbtn2.meh.fill {
  background-color: #f6941f !important;
}
.loginbtn2.success.fill {
  background-color: rgb(95, 179, 88) !important;
}
.loginbtn2.fill {
  color: white !important;
}

.loginbtn1 {
  opacity: 1;
  margin-top: 20px;
  width: 85%;
  border-radius: 7px !important;
  margin-right: 30px;
  background-color: rgb(95, 179, 88) !important;
  color: white !important;
  border-radius: 7px !important;
  border-color: rgb(95, 179, 88) !important;
  float: right;
  margin-left: 10px;
}
.custommodalinner {
  width: 600px !important;
  position: absolute;
  left: 33%;
  right: 33%;
  top: 10%;
  bottom: 10%;
  overflow-y: scroll;
  margin: auto;
  border-radius: 20px;
  background: white;
  box-shadow: 1px 1px 24px 1px #495057;
}
.delete {
  width: 25px;
}
.reset {
  margin-left: -10px;
  margin-right: -10px;
  width: 48px;
}

.edit {
  margin-left: -10px;
  margin-right: -10px;
  width: 44px;
  padding-left: 10px;
  padding-right: 10px;
}

.tablepadding {
  padding-left: 200px;
  padding-right: 200px;
}
.logo1 {
  width: 120px;
  margin-left: 70px;
}
.App {
  text-align: center;
}
.loginpad {
  border-radius: 5px;
  background: white;
  padding: 30px;
  padding-top: 30px;
  padding-bottom: 90px;
  height: 100%;
}
.imgcenter {
  text-align: center;
}
.loginpad1 {
  border-radius: 5px;
  box-shadow: 1px 1px 24px 1px green;
  background: white;
  padding: 30px;
  padding-top: 30px;
  padding-bottom: 50px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.extra {
  background-color: powderblue;
  width: 50% !important;
  margin-top: 20px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.checkbox {
  margin: 0px 5px 0 15px;
}
.checkbox-label {
  margin: 5px 30px 0 0;
}
.loginclr {
  color: white !important;
}
.App-link {
  color: #61dafb;
}
.loginbtn {
  background-color: #39b54a !important;
  color: white !important;
  font-weight: 400 !important;
  font-size: 17px !important;
  margin-top: 20px;
  border-radius: 5px;
  border-color: #39b54a !important;
}
.domain-name {
  position: relative;
  top: 6px;
  left: -20px;
}
.loginbtn1 {
  opacity: 1;
  margin-top: 25px;
  width: 85%;
  margin-right: 30px;
  background-color: rgb(95, 179, 88) !important;
  color: white !important;
  border-radius: 7px !important;
  border-color: rgb(95, 179, 88) !important;
  float: right;
  margin-left: 10px;
}
