.Login {
  min-height: 100%;
  min-width: 1024px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background-image: linear-gradient(rgb(146, 196, 108), rgb(0, 146, 69));
}
.forgot {
  text-align: right;
  margin-top: 20px;
  font-size: 14px;
  color: #6ab875;
  font-weight: 600;
}
.back {
  font-weight: 600;
  text-align: left;
  margin-top: -40px;
  font-size: 14px;
  color: #6ab875;
  font-weight: 600;
}
.backrecover {
  text-align: left;
  margin-top: 15px;
  font-size: 14px;
  color: #6ab875;
  font-weight: 600;
}
.loginlogo {
  width: 150px;

  margin-bottom: 20px;
}
.Login {
  padding: 10.25% 0;
}
@media all and (max-width: 768px) {
  .loginlogo {
    width: 150px;

    margin-bottom: 20px;
  }
  .Login {
    padding: 11.25%;
  }
  .Login {
    min-height: 100%;
    min-width: auto;

    /* Set up proportionate scaling */
    width: 100%;
    height: auto;

    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    background-image: linear-gradient(rgb(146, 196, 108), rgb(0, 146, 69));
  }
  a:hover {
    color: #39b54a;
    text-decoration: none !important;
  }
}
@media all and (min-width: 480px) {
  .Login {
    padding: 10.25% 0;
  }
  a:hover {
    color: #39b54a;
    text-decoration: none !important;
  }
  .Login form {
    margin: 0 auto;
    max-width: 490px;
  }
}
